<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
//import car from "./insert_veiculo.vue";
//import rota from "./insert_rota.vue"

import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  components: {
    Multiselect,
    //  car,
    //   rota
  },
  
  props: {
      arrayembarque: { type: Array },
      arrayveiculos1: { type: Array },
      ListPessoas: { type: Array }
  },
  data() {
    return {
      vet_pessoas:[],
      rota: {
        nome: null,
      },
      loader: { get: false },
      titleBody: "Incluir Abastecimento",
      tabelas: {
        ttp_nome: null,
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      open_modal_car: false,
      open_modal_rota: false,
      Servicos: [
        {
          id: 1,
          nome: "nome",
        },
      ],
      embarques: [
        
      ],
      combustiveis: [
        {
          id: 1,
          nome: "disel",
        },
        {
          id: 2,
          nome: "gasolina",
        },
        {
          id: 3,
          nome: "alcool",
        },
      ],

      tipos: [
        {
          id: 1,
          nome: "Kilometragem",
        },
        {
          id: 2,
          nome: "tempo",
        },
        {
          id: 3,
          nome: "Km ou tempo",
        },
      ],
      show_input_km: false,
      show_input_tempo: false,
      abastecimento: {
        motorista_id: null,
        veiculo_id: null,
        data: null,
        aviso_embarque_id:null,
        posto_prop: null,
        tp_combustivel: null,
        qtd_litros: null,
        km_inicial: null,
        p_litro: null,
        total: null,
        desc: null,
        obs: null,
        empresa_id: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      select_embarque: null,
      select_combustivel:null,
      select_total:null
    };
  },
  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },
  created() {
    this.embarques = this.arrayembarque
    this.veiculos = this.arrayveiculos1
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.vet_pessoas = this.ListPessoas
  },
  mounted() {
    console.log(this.arrayembarque)
    this.setNewTitle();
  },
  methods: {
    
    verifica_tipos(e) {
      console.log(e);
      if (e.id == 1) {
        this.show_input_km = true;
        this.show_input_tempo = false;
      } else if (e.id == 2) {
        this.show_input_km = false;
        this.show_input_tempo = true;
      } else if (e.id == 3) {
        this.show_input_km = true;
        this.show_input_tempo = true;
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // back() {
    //   this.$emit("back");
    // },
    async doPost(rota) {
      rota = this.rota;
      rota.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      //this.onLoader();
      try {
        let response = await http.post(
          "/rota?empresa_id=" + this.currentEmpresa.id,
          rota
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            //this.getData();
            //this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    modal_car() {
      this.open_modal_car = true;
    },
    modal_rota() {
      this.open_modal_rota = true;
    },
    back() {
      this.open_modal_rota = false;
      this.open_modal_car = false;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    soma_total(){
      //console.log(this.abastecimento.p_litro)
      if (typeof this.abastecimento.qtd_litros == "string" && typeof this.abastecimento.p_litro ==  "string"){
        let qtd_litro = parseInt(this.abastecimento.qtd_litros)
        let abastecimento2 = this.abastecimento
        let valorSrting = abastecimento2.p_litro
        let valorFlutuante = parseFloat(
          valorSrting.replace(/\./g, "").replace(",", ".")
        );
        let p_litro = valorFlutuante
        let total = (p_litro * qtd_litro)
        this.select_total = this.formatterCurrBR(total)
        this.abastecimento.total = total
      }
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.abastecimento.km_inicial == "string") {
        this.veiculo2 = this.abastecimento;
        let stringValor = this.veiculo2.km_inicial;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.abastecimento.km_inicial = valorFlutuante;
      }
      if (typeof this.abastecimento.p_litro == "string") {
        this.abastecimento2 = this.abastecimento;
        let stringValor = this.abastecimento2.p_litro;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.abastecimento.p_litro = valorFlutuante;
      }
    },
    setPost() {
      this.coverter_float()
      console.log(this.abastecimento.embarque)
      this.$emit("doPost", this.abastecimento);
      this.voltar();
    },
    voltar() {
      this.$emit("back");
    },
    
    seleciona_moto(event) {
      //console.log(event)
      console.log(event)
      this.abastecimento.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.abastecimento.veiculo_id = event.id;
      ///console.log(event)
    },
    seleciona_embarque(event) {
      this.abastecimento.aviso_embarque_id = event.id;
      console.log(this.abastecimento.embarque)
    },
    seleciona_combustivel(event) {
      
      this.abastecimento.tp_combustivel = event.id;
      ///console.log(event)
    },
    customLabel_car({ id, nome }) {
      return `${id} – ${nome}`;
    },
    customLabel_rota({ id, nome }) {
      return `${id} – ${nome}`;
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.abastecimento.km_inicial.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.abastecimento.km_inicial = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_p_litro() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.abastecimento.p_litro.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.abastecimento.p_litro = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        this.soma_total()
    },

  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <Label>{{ "*Motorista" }}</Label>
          <multiselect v-model="select_motorista" @select="seleciona_moto($event)" :options="vet_pessoas" label="pes_fantasia"></multiselect>
        </div>
        <div class="col-md-3">
          <Label>{{ "*Veículo" }}</Label>
          <multiselect v-model="select_veiculos" label="nomeRazao" @select="seleciona_veiculo($event)" :options="veiculos"></multiselect>
        </div>
        <div class="col-md-3">
          <Label>Data</Label>
          <input v-model="abastecimento.data" type="date" class="form-control text-left" />
        </div>
        <div class="col-md-3">
          <Label>Aviso de Embarque</Label>
          <multiselect v-model="select_embarque" @select="seleciona_embarque($event)" :options="embarques" label="id" ></multiselect>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <Label class="mr-4">Abastecimetno em Posto Proprio ?</Label>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <input v-model="abastecimento.posto_prop" type="radio" value="1" class="estilo_input" />
          <label for="" class="mr-4 ml-2">não</label>
          <input v-model="abastecimento.posto_prop" type="radio" value="2" class="estilo_input" />
          <label for="" class="ml-2">sim</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1 mt-4 mb-4" role="tab">
            <b-button block v-b-toggle.pedido variant="light"
              >+Informações Adicionais</b-button
            >
          </b-card-header>
          <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
            <div class="row">
              <div class="col-md-4">
                <Label>{{ "*Tipo de Combustivel" }}</Label>
                <multiselect v-model="select_combustivel" @select="seleciona_combustivel($event)" :options="combustiveis" label="nome"> </multiselect>
              </div>
              <div class="col-md-4">
                <Label>{{ "*Quantidade de Litros" }}</Label>
                <input v-model="abastecimento.qtd_litros" type="text" class="form-control text-left" />
              </div>
              <div class="col-md-4">
                <Label>{{ "*km inicial do Veículo" }}</Label>
                <input @input="formatInput_valor()" v-model="abastecimento.km_inicial" type="text" class="form-control text-left" />
              </div>
            </div>
            <div
              class="row mt-4 d-flex justify-content-center align-items-center"
            >
              <div class="col-md-12 text-center">
                <b-form-checkbox> Gerar Despesa Financeira </b-form-checkbox>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-4">
                <Label>{{ "*Preço Litro " }}</Label>
                <input @input="formatInput_p_litro()" v-model="abastecimento.p_litro" type="text" class="form-control text-left" />
              </div>
              <div class="col-md-4">
                <Label>{{ "*Total em R$ " }}</Label>
                <input disabled v-model="select_total" type="text" class="form-control text-left" />
              </div>
              <div class="col-md-4">
                <Label>{{ "*Descrição" }}</Label>
                <input v-model="abastecimento.desc" type="text" class="form-control text-left" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <Label>{{ "*Observação" }}</Label>
                <textarea
                  v-model="abastecimento.obs"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="form-control text-left"
                ></textarea>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="setPost()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.cor {
  background: #ced4da;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>