<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
export default {
  props: { 
      oldTabelas: { type: Object },
      listGrupos2: { type: Array },
      arrayembarque: { type: Array },
      arrayveiculos1: { type: Array },
     // parentSelected: { type: Object, required: true }
       ola: {type: Object},
       ListPessoas: { type: Array }
       
    },
  components: { 
    Multiselect 
    },
  data() {
    return {
      vet_pessoas:[],
      loader: {get: false},
      titleBody: 'Alterar Grupo',
      tabelas:{
        ttp_nome: null,
        
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      embarques: [
        
      ],
      abastecimento: {
        motorista_id: null,
        veiculo_id: null,
        data: null,
        aviso_embarque_id:null,
        posto_prop: null,
        tp_combustivel: null,
        qtd_litros: null,
        km_inicial: null,
        p_litro: null,
        total: null,
        desc: null,
        obs: null,
        empresa_id: null,
      },
      show_input_km: false,
      show_input_tempo: false,
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      select_embarque: null,
      select_combustivel:null,
      select_total:null,
      rota: {
        nome: null,
      }, 
      combustiveis: [
        {
          id: 1,
          nome: "disel",
        },
        {
          id: 2,
          nome: "gasolina",
        },
        {
          id: 3,
          nome: "alcool",
        },
      ],
    }
  },
  validations: {
    tabelas:{
        ttp_nome: {required}, 
      }
  },
  created(){
   this.rota = this.oldTabelas
   this.veiculos = this.arrayveiculos1 
   this.embarques = this.arrayembarque
   this.abastecimento = this.oldTabelas
   this.select_total = this.oldTabelas.total
   this.vet_pessoas = this.ListPessoas
   
  },
  mounted() {
    this.setNewTitle();
    console.log("Monstrando Objeto Abastecimento")
    console.log(this.abastecimento)
    this.insert_embarque = this.ola
     this.select_motorista =  this.ListPessoas.find((p)=>p.id == this.oldTabelas.motorista_id)
    console.log(this.select_motorista)
  },
  methods: {
    verifica_tipos(e) {
      console.log(e);
      if (e.id == 1) {
        this.show_input_km = true;
        this.show_input_tempo = false;
      } else if (e.id == 2) {
        this.show_input_km = false;
        this.show_input_tempo = true;
      } else if (e.id == 3) {
        this.show_input_km = true;
        this.show_input_tempo = true;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.abastecimento.km_inicial == "string") {
        this.veiculo2 = this.abastecimento;
        let stringValor = this.veiculo2.km_inicial;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.abastecimento.km_inicial = valorFlutuante;
      }
      if (typeof this.abastecimento.p_litro == "string") {
        this.abastecimento2 = this.abastecimento;
        let stringValor = this.abastecimento2.p_litro;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.abastecimento.p_litro = valorFlutuante;
      }
    },
    soma_total(){
      //console.log(this.abastecimento.p_litro)
      if (typeof this.abastecimento.qtd_litros == "string" && typeof this.abastecimento.p_litro ==  "string"){
        let qtd_litro = parseInt(this.abastecimento.qtd_litros)
        let abastecimento2 = this.abastecimento
        let valorSrting = abastecimento2.p_litro
        let valorFlutuante = parseFloat(
          valorSrting.replace(/\./g, "").replace(",", ".")
        );
        let p_litro = valorFlutuante
        let total = (p_litro * qtd_litro)
        this.select_total = this.formatterCurrBR(total)
        this.abastecimento.total = total
      }
    },
    setPut() {
      this.coverter_float()
      console.log('Gravou');
      console.log(this.abastecimento)
      this.$emit('doPut', this.abastecimento);
    },
    
    seleciona_moto(event) {
      //console.log(event)
      this.abastecimento.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.abastecimento.veiculo_id = event.id;
      ///console.log(event)
    },
    seleciona_embarque(event) {
      this.abastecimento.aviso_embarque_id = event.id;
      console.log(this.abastecimento.aviso_embarque_id)
    },
    seleciona_combustivel(event) {
      
      this.abastecimento.tp_combustivel = event.id;
      ///console.log(event)
    },
    doPut(){
      this.$emit('doPut',this.rota)
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.abastecimento.km_inicial.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.abastecimento.km_inicial = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_p_litro() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.abastecimento.p_litro.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.abastecimento.p_litro = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
        this.soma_total()
    },
  },
    
}
</script>

<template>

<div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <Label>{{ "*Motorista" }}</Label>
          <multiselect v-model="select_motorista" @select="seleciona_moto($event)" :options="vet_pessoas" label="pes_fantasia"></multiselect>
        </div>
        <div class="col-md-3">
          <Label>{{ "*Veículo" }}</Label>
          <multiselect v-model="select_veiculos" label="nome" @select="seleciona_veiculo($event)" :options="veiculos"></multiselect>
        </div>
        <div class="col-md-3">
          <Label>Data</Label>
          <input v-model="abastecimento.data" type="date" class="form-control text-left" />
        </div>
        <div class="col-md-3">
          <Label>Aviso de Embarque</Label>
          <multiselect v-model="select_embarque" @select="seleciona_embarque($event)" :options="embarques" label="nome"></multiselect>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <Label class="mr-4">Abastecimetno em Posto Proprio ?</Label>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <input v-model="abastecimento.posto_prop" type="radio" value="1" class="estilo_input" />
          <label for="" class="mr-4 ml-2">não</label>
          <input v-model="abastecimento.posto_prop" type="radio" value="2" class="estilo_input" />
          <label for="" class="ml-2">sim</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1 mt-4 mb-4" role="tab">
            <b-button block v-b-toggle.pedido variant="light"
              >+Informações Adicionais</b-button
            >
          </b-card-header>
          <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
            <div class="row">
              <div class="col-md-4">
                <Label>{{ "*Tipo de Combustivel" }}</Label>
                <multiselect v-model="select_combustivel" @select="seleciona_combustivel($event)" :options="combustiveis" label="nome"> </multiselect>
              </div>
              <div class="col-md-4">
                <Label>{{ "*Quantidade de Litros" }}</Label>
                <input v-model="abastecimento.qtd_litros" type="text" class="form-control text-left" />
              </div>
              <div class="col-md-4">
                <Label>{{ "*km inicial do Veículo" }}</Label>
                <input @input="formatInput_valor()" v-model="abastecimento.km_inicial" type="text" class="form-control text-left" />
              </div>
            </div>
            <div
              class="row mt-4 d-flex justify-content-center align-items-center"
            >
              <div class="col-md-12 text-center">
                <b-form-checkbox> Gerar Despesa Financeira </b-form-checkbox>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-4">
                <Label>{{ "*Preço Litro " }}</Label>
                <input @input="formatInput_p_litro()" v-model="abastecimento.p_litro" type="text" class="form-control text-left" />
              </div>
              <div class="col-md-4">
                <Label>{{ "*Total em R$ " }}</Label>
                <input disabled v-model="select_total" type="text" class="form-control text-left" />
              </div>
              <div class="col-md-4">
                <Label>{{ "*Descrição" }}</Label>
                <input v-model="abastecimento.desc" type="text" class="form-control text-left" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <Label>{{ "*Observação" }}</Label>
                <textarea
                  v-model="abastecimento.obs"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="form-control text-left"
                ></textarea>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="setPut()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>